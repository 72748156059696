Mavo.Locale.register("en", {
	"second": "second",
	"seconds": "seconds",
	"minute": "minute",
	"minutes": "minutes",
	"hour": "hour",
	"hours": "hours",
	"day": "day",
	"days": "days",
	"week": "week",
	"weeks": "weeks",
	"month": "month",
	"months": "months",
	"year": "year",
	"years": "years",
	"edit": "Edit",
	"editing": "Editing",
	"save": "Save",
	"import": "Import",
	"export": "Export",
	"logout": "Logout",
	"login": "Login",
	"loading": "Loading",
	"uploading": "Uploading",
	"saving": "Saving",
	"dismiss": "Dismiss",
	"logged-in-as": "Logged in to {id} as ",
	"login-to": "Login to {id}",
	"error-uploading": "Error uploading file",
	"cannot-load-uploaded-file": "Cannot load uploaded file",
	"filename": "Filename?",
	"problem-saving": "Problem saving data",
	"problem-loading": "Problem loading data",
	"cannot-parse": "Can’t understand this file",
	"http-error": "HTTP error {status}: {statusText}",
	"cant-connect": "Can’t connect to the Internet",
	"add-item": "Add {name}",
	"add-item-before": "Add new {name} before",
	"add-item-after": "Add new {name} after",
	"drag-to-reorder": "Drag to reorder {name}",
	"delete-item": "Delete this {name}",
	"item-deleted": "{name} deleted",
	"n-items": "{n} {name} items",
	"undo": "Undo",
	"gh-updated-file": "Updated {name}",
	"gh-login-fork-options": "You have your own copy of this page, would you like to use it?",
	"gh-use-my-fork": "Yes, show me my data.",
	"remote-data-conflict": "There is new data but you have unsaved changes. Loading it will overwrite your changes. Load new data?"
});
